import { throttle } from 'lodash';

export default function init() {
  const target = document.querySelector('.b-site-header-wrapper');
  if (!target) return;
  let checkbox = target.querySelector('#nav-toggle');
  if (!checkbox) return;
  let lastY = window.scrollY;


  const closeMenu = () => checkbox?.checked && (checkbox.checked = false);

  // Throttle the scroll handler to run at most once every 100ms
  const handleScroll = throttle(() => {
    closeMenu();

    const currentScrollY = window.scrollY;
    if (currentScrollY > lastY) {
      // Scrolling down
      if (currentScrollY < target.clientHeight) {
        target.classList.remove('is-active');
        target.classList.remove('is-hidden');
        target.classList.remove('is-scrolling');
        return;
      }
      if (!target.classList.contains('is-active')) {
        target.classList.remove('is-hidden');
        target.classList.add('is-active');
      }
    } else {
      // Scrolling up
      if (currentScrollY < target.clientHeight) {
        target.classList.add('is-active');
        target.classList.remove('is-hidden');
        target.classList.remove('is-scrolling');
        return;
      }
      if (target.classList.contains('is-active')) {
        target.classList.remove('is-active');
        target.classList.add('is-hidden');
      }
    }

    lastY = currentScrollY;
  }, 100); // 100ms throttle time - adjust as needed


  window.addEventListener('touchmove', closeMenu);
  window.addEventListener('gesturechange', closeMenu);

  window.addEventListener('scroll', handleScroll, false);

}
